.clinicanInfoContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: #364469;
    padding: 32px 40px;
    border-radius: 12px;
    border: 1px solid #42548B;
    margin: 32px 0 40px 0;
    gap: 32px;

    &__details {
        h5 {
            color: #E7E7E7;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 2%;
            font-weight: 400;
            margin: unset;
        }

        p {
            color: #ffff;
            font-size: 16px;
            font-weight: 500;
            margin: 8px 0 0 0;
        }

        &__status {
            display: flex;
            align-items: center;
            gap: 7px;
            margin-top: 8px;
        }

        &__indicator {
            display: flex;
            align-items: center;
            gap: 7px;

            span {
                text-transform: capitalize;
                color: #ffff;
                font-size: 16px;
            }
        }
    }
}

.patientDetails {
    &__actionBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    &__date {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #83889D;
        margin-bottom: 16px;
    }
}

.reportClinicianContainer {
    &__heading {
        display: flex;
        align-items: center;
        gap: 24px;

        h2 {
            font-weight: 500;
            font-size: 28px;
            line-height: 32px;
            color: #364469;
        }

        img {
            cursor: pointer;
        }
    }
}