.admin {
  &__add {
    display: flex;
    justify-content: flex-end;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    padding-top: 2%;
  }

  &__fil__img {
    position: relative;
    top: 9%;
    padding-left: 12%;
  }

  &__table {
    padding-top: 0.1rem;
  }

  &__status {
    display: flex;
    flex-direction: row;
    gap: 14%;
    text-transform: capitalize;
  }
}

.admin__length {
  color: #83889d;
  padding-top: 0.4%;
}

.adminInvite {
  &__btns {
    display: flex;
    justify-content: flex-start;
    margin: 3%;
  }
  &__btn {
    display: flex;
    flex-direction: row;
    gap: 17%;
    background-color: #fafafa;
    color: #425466;
    border: 1px solid #c1c6d3;
    border-radius: 20px;
    padding: 8px 15px;
    width: 100px;
    font-size: 16px;
    cursor: pointer;
  }

  &__close {
    position: relative;
    top: -7%;
  }

  h1,
  p {
    text-align: center;
    color: #425466;
    font-size: 16px;
  }

  h1 {
    color: #0b2253;
    font-size: 24px;
    font-weight: 500;
  }
}

.filterBtn {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}
.indicatorWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}
